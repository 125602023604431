import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import { useEffect, useState } from "react";
import Verify from "views/auth/Verify";
import Settings from "views/auth/Settings";

export default function Auth() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    !token ? setIsAuthenticated(false) : setIsAuthenticated(true)
  }, [token]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        if (
          prop.path === "landing" ||
          prop.path === "qr-scanner" ||
          prop.path === "default" ||
          prop.path === "sign-in" ||
          prop.path === "sign-up" ||
          prop.path === "search-event" ||
          prop.path === "regular-sign-up" ||
          prop.path === "verify"
        ) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
        } else {
          return (
            <Route
              path={`/${prop.path}`}
              element={
                !isAuthenticated ? (
                  <Navigate to={"/auth"} replace />
                ) : (
                  prop.component
                )
              }
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full bg-gradient-to-br from-white via-white to-gray-200 dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-0 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div
                className={
                  "mb-auto flex flex-col xs:pr-1 xs:pr-1 sm:pl-5 sm:pr-5 md:pr-5 md:pl-5 lg:max-w-[48%] lg:pl-0 xl:max-w-full"
                }
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={isAuthenticated && JSON.parse(localStorage.getItem("userDetails")) ? <Navigate to={"/auth/events"} replace /> : <Navigate to="/auth/sign-in" replace />}
                  />
                  <Route
                    path={`/verify`}
                    element={
                      <Verify
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                      />
                    }
                  />
                  <Route
                    path={`/settings`}
                    element={isAuthenticated ?
                      <Settings
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                      /> : <Navigate to="/auth/sign-in" replace />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
