import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import { emailMaskGenerator } from "utils/utils";
import InputField from "components/fields/InputField";
import 'react-toastify/dist/ReactToastify.css';


const emailMask = "*********@****.***";
const maskGenerator = emailMaskGenerator(emailMask);

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegular, setIsRegular] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    setPhoneNumber(inputValue);

    // Email syntax validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailPattern.test(inputValue));
  };

 

  

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };


  const handleLogin = async () => {
    if (isValid && phoneNumber) {
      setLoading(true);
      localStorage.setItem('userEmail', JSON.stringify(phoneNumber))
      const endpoint = `Verification/EmailVerificationCode/${phoneNumber}`
      const response = await httpService("POST", endpoint, {});
      if (response.Error === "HttpException") {
        setLoading(false);
        toast.error(response.Message);
      } else {
        if (response) {
          setLoading(false);
          localStorage.setItem("userDetails", JSON.stringify(response));
          toast('✔ Check your inbox and spam for your code.')
          navigate("/auth/verify", {
            state: {
              isRegular: isRegular,
            },
          });
        }
      }
    }
  };



  return (
    <div className="flex pt-0 h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#252424" />
      {/* Sign in section */}
      <div className="mt-0 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <Header
          tagline={
            "Go out, find your crowd"
          }
        />
<div className="mt-8">
        <h4 className="ml-2 mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-gray-500">
          Discover who’s in the room
        </p>
        {/* Email */}
        <InputField
          autoFocus
          className={`dark:text-black mt-4 flex h-12 w-full items-center justify-center rounded-xl border ${isValid ? 'border-gray-200' : 'border-red-500' // Applying border color based on validation
            } p-3 text-sm outline-none ${isValid ? 'dark:!border-white/10' : '' // Additional style if valid
            }`}
          placeholder="Your Email"
          inputMode="email"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        {!isValid && (
          <p className="text-red-500 text-xs mt-1">Please enter a valid email address</p>
        )}
        <button
          onClick={() => handleLogin()}
          disabled={!isValid} // Disable the button if isValid is false
          className={
            "linear mt-10 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 " +
            (isValid
              ? "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "bg-gray-500 cursor-not-allowed opacity-50 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200")
          }
        >
          Check-in
        </button>


        <div className="signIn-notes mt-8">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
            You're one connection away from your next goal.
          </span>

        </div>
      </div>
    </div>
    </div>
  );
}

