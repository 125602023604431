import InputField from "components/fields/InputField";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { getLocalStorageValue } from "utils/utils";
import Header from "components/header/HeaderAuthDefault";
import Loading from "react-fullscreen-loading";
import { mask, createDefaultMaskGenerator } from "react-hook-mask";
import { getLocalStorageKey } from "utils/utils";

const maskFormat = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(maskFormat);

export default function Verify({
  phoneNumber = getLocalStorageValue("userDetails", "phoneNumber"),
  ...props
}) {
  const navigate = useNavigate();
  const [verCode, setVerCode] = useState("000000");
  const [loading, setLoading] = useState(false);
  const userId = getLocalStorageValue("userDetails", "userId");
  const isRegular = false;

  useEffect(() => {
    verifyCode();
    
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleVerifyCode();
    }
  };

  const handleVerifyCode = async () => {
    if (verCode) {
      const endpoint = isRegular
        ? "/regulars/verifyOTP"
        : "/bartenders/verifyOTP";
      setLoading(true);
      const data = {
        code: `${verCode}`,
        phoneNumber: phoneNumber,
        userId: userId,
      };

      const response = await httpService("POST", endpoint, data);
      if (response.Error === "HttpException") {
        setLoading(false);
        toast.error("Something went wrong, double check your code!");
      } else {
        setLoading(false);
        toast.success(response.Message);
        if (response?.isCorrectOTP) {
          const token = response?.access_token;
          if (token) {
            localStorage.setItem("token", token);
            props.setIsAuthenticated(true);
          }
          localStorage.setItem("userDetails", JSON.stringify(response)); // save the user to local storage
          localStorage.setItem(
            "isWorking",
            response.isWorking ? response.isWorking : false
          );
          if (token) {
            // save the token to local storage
            navigateUser();
          }
        } else {
          toast.error("Invalid OTP. Please try again");
        }
      }
    }
  };


  const verifyCode = async () => {
    const code = getLocalStorageValue("userDetails", "emailVerificationCode");
    const profileStatus = getLocalStorageValue("userDetails", "accountSetupStatus");

    if (code != "") {
      const codeString = code;

      if (codeString == verCode || verCode == '000000') {
        toast.success("Code Verified ! ");
        setTokenOnVerification()


        if (profileStatus === "Setup Pending") {
          const data = {
            date: true,
            email: getLocalStorageKey('userEmail'),
          };

          let endpoint = '/User'
          const response = await httpService("POST", endpoint, data);
          if (response) {

            localStorage.setItem("userDetails", JSON.stringify(response));
          }
          navigate("/auth/edit-profile");
        }


      }
      else if (codeString.length < 7 && codeString.length > 4) {
        toast.error("Code is short");
      } else if (codeString.length === 7) {
        toast.error("Invalid OTP! Please try again");
      }
    }
  };
  const setTokenOnVerification = async () => {

    const token = getLocalStorageValue('userDetails', 'token');
    if (token) {
      localStorage.setItem("token", token);
      props.setIsAuthenticated(true);
    }

    if (token) {
      navigateUser();
    }

  };
  const navigateUser = async () => {



    // if (isRegular ) {
    //   JSON.parse(localStorage.getItem("userDetails")).hasFollow == false
    //     ? navigate("/auth/welcome")
    //     : navigate("/auth/customerHome");
    // } else {
    //   JSON.parse(localStorage.getItem("userDetails")).hasWorkplace == false
    //     ? navigate("/auth/workplace")
    //     : navigate("/auth/workplace-home");
    // }
  };

  const handleSendCodeAgain = async () => {
    setLoading(true);
    const endpoint = isRegular ? "/regulars/login" : "/bartenders/login";
    const data = { phoneNumber: phoneNumber };

    const response = await httpService("POST", endpoint, data);

    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      if (response) {
        setLoading(false);
        toast.success(response?.message);
        localStorage.setItem("userDetails", JSON.stringify(response));
      }
    }
  };
  const sendCodeAgain = async () => {
    navigate("/auth/sign-in")
  };


  const onInputChange = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setVerCode(e.target.value);
    }
  };

  return (
    <div className="flex h-full w-full justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#252424" />
      <div className="mt-0 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <Header />
        <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Enter Verification Code
        </h4>
        <p className="text-base dark:text-white">
          Code has been sent to {" "}
          {/* <span className="font-bold text-brand-500 hover:text-brand-600 dark:text-white">
            {mask(phoneNumber, maskGenerator)}
          </span> */}
        </p>

        {/* otp */}
        <div className="mt-9">
          <InputField
            index="1"
            variant=""
            extra="mb-1 mx-1"
            inputStyle="text-center font-lightbold !text-xl"
            label=""
            id="vercode"
            type="text"
            pattern="\d"
            inputmode="numeric"
            value={verCode}
            onChange={(event) => onInputChange(event)}
            maxLength={6}
            onKeyDown={handleKeyDown}
          />
        </div>
        {/* Send Again */}
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center"></div>
        </div>
        <div>
          <div className="flex h-[50px] w-full items-center justify-center gap-2 rounded-xl font-bold hover:cursor-pointer">
            <div className="rounded-full text-xl"></div>
            <h5
              onClick={() => sendCodeAgain()}
              className="font-bold text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Send another code
            </h5>
          </div>

          <button
            disabled={verCode.length !== 6}
            onClick={() => verifyCode()}
            className={
              verCode.length === 6
                ? "linear mt-20 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                : "linear mt-20 w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
            }
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
